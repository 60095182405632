@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://use.typekit.net/wpb7vfu.css");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .coracleLoader {
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .coracleLoader span {
    position: absolute;
    color: rgba(255, 255, 255, 0.09);
    transform: translate(-50%, -50%);
    font-size: 4rem;
    letter-spacing: 5px;
  }

  .coracleLoader span:nth-child(1) {
    /* color: transparent; */
    color: rgba(255, 255, 255, 0.05);
  }

  .coracleLoader span:nth-child(2) {
    color: rgba(255, 255, 255, 1);
    -webkit-text-stroke: 1px black;
    animation: coracleLoader 3s ease-in-out infinite;
  }

  @keyframes coracleLoader {
    0%,
    100% {
      clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
    }

    50% {
      clip-path: polygon(0% 60%, 16% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
    }
  }

  .nav-shadow {
    -webkit-box-shadow: 0px 0px 40px -6px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0px 0px 40px -6px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 40px -6px rgba(255, 255, 255, 0.1);
  }
  .amhHero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.73)), url("./assets/amhLowerLevel.webp");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .max-w-content {
    padding: 0px 10px;
  }
  .amhStripes {
    background: linear-gradient(#e9ecef 0 0) calc(1 * 100% / 6), linear-gradient(#e9ecef 0 0) calc(2 * 100% / 6),
      linear-gradient(#e9ecef 0 0) calc(3 * 100% / 6), linear-gradient(#e9ecef 0 0) calc(4 * 100% / 6), linear-gradient(#e9ecef 0 0) calc(5 * 100% / 6);
    background-size: "2px 100%";
    background-repeat: "no-repeat";
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.ant-input {
  padding: 10px 15px !important;
}
.ant-input:focus {
  border-color: #424242 !important;
  box-shadow: none !important;
}
.ant-input:hover {
  border-color: #a8a8a8 !important;
}
.ant-input-number {
  padding: 6px 5px !important;
}
.ant-input-number:focus {
  border-color: #424242 !important;
  box-shadow: none !important;
}
.ant-input-number-focused {
  border-color: #424242 !important;
  box-shadow: none !important;
}
.ant-input-number:hover {
  border-color: #a8a8a8 !important;
}
.maxZIndex {
  z-index: 9999 !important;
}
.ant-table-cell {
  background-color: #000 !important;
}
:where(.css-dev-only-do-not-override-qqi6is).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-qqi6is).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.autocomplete-dropdown-container {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 7px 1px rgba(163, 163, 163, 0.25);
  -moz-box-shadow: 0px 3px 7px 1px rgba(163, 163, 163, 0.25);
  box-shadow: 0px 3px 7px 1px rgba(163, 163, 163, 0.25);
  padding: 10px;
  position: absolute;
  width: 100%;
  /* max-width: 600px; */
  z-index: 100;
}

.autocomplete-dropdown-container:empty {
  display: none;
}
.suggestion-item,
.suggestion-item--active {
  padding: 5px 0px;
}

.ant-btn-primary {
  color: #fff !important;
  background-color: #172e96 !important;
  box-shadow: 0 2px 0 rgba(131, 146, 246, 0.13) !important;
}
.ant-btn-primary:hover {
  color: #fff !important;
  background-color: #334da9 !important;
}

mapbox-address-autofill {
  width: 100% !important;
}

[class$="--ResultsAttribution"] {
  display: none !important;
}
.quill-editor .ql-toolbar,
.quill-editor .ql-container {
  background-color: #1a202c;
  color: #e2e8f0;
  border-color: #4a5568;
}
.quill-editor .ql-picker-label,
.quill-editor .ql-picker-item {
  color: #e2e8f0;
}
.quill-editor .ql-snow.ql-toolbar button:hover,
.quill-editor .ql-snow.ql-toolbar button:focus,
.quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #60a5fa;
}
.quill-editor .ql-snow.ql-toolbar button svg,
.quill-editor .ql-snow.ql-toolbar .ql-picker-label svg {
  fill: #e2e8f0;
}

.ql-editor {
  min-height: 300px;
  height: 100%;
  max-height: 450px;
}
